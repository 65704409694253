import _ from "lodash";
import { SQLDate, SQLDateTime } from "../shared-domain/util";
import md5 from "md5";
import { DateTime } from "luxon";

import {
  SpektrixPrice,
  TopTixPrice,
  EntaPrice,
  AVPrice,
  NLivenPrice,
  PropPrice,
  SeePrice,
  ShowOptions,
} from "../../../seesyncer/src/domain/syncer-ticketing";

import { Price as LineupPrice } from "../../../seesyncer/src/lineup/lineupTypes";

export type Source = "tkts-online" | "tkts-booth" | "olt" | "kw" | "gilt" | "kw-ext" | "day-seats";
export type TKTSSource = Extract<Source, "tkts-booth" | "tkts-online">;

export type Provider =
  | "toptix"
  | "spektrix"
  | "prop"
  | "enta"
  | "nliven"
  | "av"
  | "ingresso"
  | "lineup";

export type SeeProvider = Exclude<Provider, "ingresso">;
export type SeeConnectedProvider = Exclude<SeeProvider, "prop">;
export type SeeTicketsOffercode = "olt" | "TKTS";

export interface ProviderSourcePair {
  provider: Provider;
  source: Source;
}

export function isValidProvider(provider: string): provider is Provider {
  return _.includes(
    ["prop", "toptix", "spektrix", "enta", "ingresso", "av", "nliven", "lineup"],
    provider
  );
}

export function isValidSource(source: string): source is Source {
  return _.includes(["olt", "kw", "gilt", "tkts-online", "tkts-booth", "kw-ext", "day-seats"], source);
}

export function isTKTSSource(source: Source): source is "tkts-online" | "tkts-booth" {
  return _.includes(["tkts-online", "tkts-booth"], source);
}

export function isValidProviderSourcePair(
  pair: ProviderSourcePair
): pair is ProviderSourcePair {
  return isValidProvider(pair.provider) && isValidSource(pair.source);
}

export interface EntaPriceFilter {
  PriceBand: string;
  SeatBlock: string;
  DiscountGroup: string;
  DiscountCode: string;
  SalePrice: number;
  hidden: true;
}

export interface TopTixPriceFilter {
  AreaMapName: string;
  BandName: string;
  TicketCost: number;
  hidden: boolean;
}

export interface SpektrixPriceFilter {
  BandName: string;
  PlanName: string;
  TicketCost: number;
  hidden: boolean;
}

export interface AVPriceFilter {
  BandName: string;
  Block: string;
  TicketCost: number;
  hidden: boolean;
}

export interface NLivenPriceFilter {
  BandName: string;
  Block: string;
  TicketCost: number;
  hidden: boolean;
}

export interface PropPriceFilter {
  Type: number;
  hidden: boolean;
}

export interface LineupPriceFilter {
  location: string;
  priceband: string;
  ticketprice: number;
  hidden: boolean;
}

export type SeePriceFilter =
  | EntaPriceFilter
  | TopTixPriceFilter
  | SpektrixPriceFilter
  | AVPriceFilter
  | PropPriceFilter
  | LineupPriceFilter;

export interface DBPerformancePrice {
  onsale: boolean;
  location: string;
  status: "Order" | "Sold Out";
  facevalue: number;
  ticketprice: number;
  allPartsTicketPrice?: number;
  priceband: string;
  fullprice?: number;
  hidden?: boolean;
  offerName?: string | undefined;
  availability?: number;
}

export interface InvalidDBPerformancePrice extends DBPerformancePrice {
  reason: "invalid_booking_fee" | "no_matching_fullprice";
}

export interface PricesAndInvalidPrices {
  validPrices: DBPerformancePrice[];
  invalidPrices: InvalidDBPerformancePrice[];
}

// TODO: what about the price summary information we usually have (price_from etc)
// TODO: created/modified not always present - when constructing a new performance?
export interface DBPerformanceBase {
  created: SQLDate;
  modified: SQLDate;
  show_uid: number | null;
  perf_uid: number | null;
  show_name: string;
  venue_name: string;
  show_url: string;
  show_url_hash: string;
  perf_url: string;
  perf_url_hash: string;
  hash: string;
  starts: SQLDateTime;
  time_slot: "matinee" | "evening";
  status: "Order" | "Sold Out";
  price_from: number | null;
  price_to: number | null;
  available: boolean;
  source: Source;
  prices: DBPerformancePrice[];
  invalid_prices: InvalidDBPerformancePrice[] | null;
  nys_pricepoint_10_available: boolean | null;
  nys_pricepoint_20_available: boolean | null;
  nys_pricepoint_30_available: boolean | null;
  nys_pricepoint_40_available: boolean | null;
  nys_pricepoint_50_available: boolean | null;
  nys_pricepoint_60_available: boolean | null;
  discount_available: boolean | null;
  end_sale_at: string;
}

export interface IngressoDBPerformance extends DBPerformanceBase {
  provider: "ingresso";
  see_prices: null;
  see_price_filters: null;
}

export interface SpektrixDBPerformance extends DBPerformanceBase {
  provider: "spektrix";
  see_prices: SpektrixPrice[] | null;
  see_price_filters: SpektrixPriceFilter[] | null;
}

export interface TopTixDBPerformance extends DBPerformanceBase {
  provider: "toptix";
  see_prices: TopTixPrice[] | null;
  see_price_filters: TopTixPriceFilter[] | null;
}

export interface EntaDBPerformance extends DBPerformanceBase {
  provider: "enta";
  see_prices: EntaPrice[] | null;
  see_price_filters: EntaPriceFilter[] | null;
}

export interface AVDBPerformance extends DBPerformanceBase {
  provider: "av";
  see_prices: AVPrice[] | null;
  see_price_filters: AVPriceFilter[] | null;
}

export interface NLivenDBPerformance extends DBPerformanceBase {
  provider: "nliven";
  see_prices: NLivenPrice[] | null;
  see_price_filters: NLivenPriceFilter[] | null;
}

export interface PropDBPerformance extends DBPerformanceBase {
  provider: "prop";
  see_prices: PropPrice[] | null;
  see_price_filters: PropPriceFilter[] | null;
}

export interface LineupDBPerformance extends DBPerformanceBase {
  provider: "lineup";
  see_prices: LineupPrice[] | null;
  see_price_filters: LineupPriceFilter[] | null;
}

export type SeeDBPerformance =
  | SpektrixDBPerformance
  | TopTixDBPerformance
  | EntaDBPerformance
  | AVDBPerformance
  | NLivenDBPerformance
  | PropDBPerformance
  | LineupDBPerformance;

export type DBPerformance = SeeDBPerformance | IngressoDBPerformance;

export interface CMSShowInfo extends DBPerformanceBase {
  id: string;
  shortTitle: string;
  searchTitle: string;
  url: string;
  synopsis: string;
  image: string;
  genres: any[];
  booking_until: SQLDate;
  previews_from: SQLDateTime;
  opening_night: SQLDateTime;
  closing_night: SQLDateTime;
  olivier_nominated: boolean;
  olivier_winner: boolean;
  currentlyOpen: boolean;
  isReopening: boolean;
  mothballed: boolean;
  accessOnly: boolean;
  notClosed: boolean;
  showTypes: any[];
  venue: {
    id: number;
    title: string;
  };
  minimumAdmissionAge: string;
  ageRecommendation: string;
  advisoryContent: string;
  showOfTheWeek: boolean;
  showUrls: string[];
  pricesFrom:
    | [
        {
          provider: string;
          source: string;
          timeSlot: string;
          priceFrom: number | null;
        }
      ]
    | [];
  performances: [
    {
      starts: SQLDateTime;
      date: SQLDate;
      time: string;
      available: boolean;
      performanceURLs: [
        {
          available: boolean;
          prices: DBPerformancePrice[];
          provider: string;
          source: string;
          show_url: string;
          url: string;
          markBackTime: string;
          tktsInPersonOnly?: boolean;
        }
      ];
    }
  ];
  externalUrl: string;
  seeItSafelyApproved: boolean;
  hideFromKidsWeekGrid: boolean;
  accessPerformances: any[];
}

export type TKTSBoardPerformance = Pick<
  DBPerformance,
  | "created"
  | "modified"
  | "perf_url"
  | "show_url"
  | "show_url_hash"
  | "hash"
  | "venue_name"
  | "show_name"
  | "show_url_hash"
  | "starts"
  | "prices"
  | "source"
  | "provider"
> & {
  is_multipart: boolean;
  part_number?: number;
};

export function priceHash(
  perf_url: string,
  price: {
    location: string;
    priceband: string;
    facevalue: number;
    ticketprice: number;
    fullprice?: number;
  }
): string {
  return _.join(
    [
      perf_url,
      price.location,
      price.priceband,
      price.facevalue,
      price.fullprice,
      price.ticketprice,
    ],
    "|"
  );
}

export function generateHash({
  provider,
  source,
  attributes,
}: {
  provider: Provider;
  source: Source;
  attributes: {
    show_uid: number | null;
    perf_uid: number | null;
    showName: string | undefined;
    venueName: string | undefined;
    starts: SQLDateTime | undefined;
  };
}) {
  let vs;

  if (provider === "prop") {
    const { perf_uid, starts } = attributes;
    vs = [provider, source, perf_uid, starts];
  } else if (provider === "ingresso") {
    const { showName, venueName, starts } = attributes;
    vs = [provider, source, showName, venueName, starts];
  } else {
    const { show_uid, starts } = attributes;
    vs = [provider, source, show_uid, starts];
  }

  if (!_.every(vs)) {
    throw new Error("Cannot generate hash: " + _.join(vs, "|"));
  }

  return md5(_.join(vs, "|"));
}

export function generateURLHash(url: string): string {
  if (!url) {
    throw new Error("Cannot generate hash: " + url);
  }

  const httpRegExp = new RegExp("^(http|https)://", "i");
  const baseURL = url.replace(httpRegExp, "").split("?")[0];

  return md5(baseURL);
}

export function performanceDate(starts: SQLDateTime): SQLDate {
  // starts is SQLDateTime, so YYYY-MM-DD HH:mm:ss,
  // so extract the first YYYY-MM-DD
  return starts.substr(0, 10);
}

export function isMatineeTime(datetime: SQLDateTime) {
  const dt = DateTime.fromSQL(datetime);

  // check greater than 3am just in case we get any very
  // late-night-into-early-morning starts time (they would
  // be considered evening performances)
  return dt.hour > 4 && dt.hour < 18;
}

export function isEveningTime(datetime: SQLDateTime) {
  return !isMatineeTime(datetime);
}

export function generateIngressoPerformanceURL(
  baseURL: string,
  eventPath: string,
  performance: {
    time_desc: string;
    iso8601_date_and_time: string;
  }
) {
  const performanceID = _.get(performance, ["perf_id"]);
  const time = _.get(performance, ["time_desc"]).replace(" ", "");
  const date = _.get(performance, ["iso8601_date_and_time"]).split("T")[0];
  return `${baseURL}/book${eventPath}#perf=${performanceID}&date=${date}&time=${time}`;
}

export function excludeShowFromSync(showOptions: ShowOptions[], performance: DBPerformance) {
  return _.find(showOptions, option => option.show_url === performance.show_url && option.solt_disable_show_sync === 1);
}
